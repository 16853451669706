
import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { auth, db } from './firebase';
import QuestionData, { version } from './BABBAR450.js';
import QuestionData1, { version1 } from './StriverList';
import AmazonList from './AmazonList';
import GoogleList from './GoogleList';
import MicrosoftList from './MicrosoftList';
import Spinner from 'react-bootstrap/Spinner';

function Registration() {
    const history = useHistory();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const handleRegister = (e) => {
        e.preventDefault();
        setLoading(true);
        if (password !== confirmPassword) {
            setError("Passwords don't match");
            setLoading(false);
            return;
        }

        const isGmail = /@gmail\.com$/i.test(email);
        if (!isGmail) {
            setError('Please use a Gmail account for registration');
            setLoading(false);
            return;
        }

        // Firebase registration logic
        auth.createUserWithEmailAndPassword(email, password)
            .then((authUser) => {
                if (authUser) {
                    db.collection('users')
                        .doc(authUser.user.uid)
                        .set({
                            babbarList: QuestionData,
                            striverList: QuestionData1,
                            amazonList: AmazonList,
                            googleList: GoogleList,
                            microsoftList: MicrosoftList,
                        })
                        .then(() => {

                            history.push('/'); // Redirect to the desired page after registration
                            setLoading(false);
                        })
                        .catch((error) => {
                            console.error('Error adding document: ', error);
                        });
                }
            })
            .catch((error) => {
                
                const startIndex = error.message.indexOf(':') + 2;
                const endIndex = error.message.indexOf('(', startIndex);
                const trimmedErrorMessage = error.message.substring(startIndex, endIndex);
                
                setError(trimmedErrorMessage);
                setLoading(false);
              });
    };

    return (
        <div className="login">
            <Link to="/" className="login__logo" style={{ textDecoration: 'none' }}>
            Tech<span>At</span>Phone <span>S</span>heet
            </Link>

            <div className="login__container">
                <h2>Sign In</h2>

                <form>
                    <h5>E-mail</h5>
                    <input
                        type="text"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />

                    <h5>Password</h5>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />

                    <h5>Confirm Password</h5>
                    <input
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />

                    {error && <p style={{ color: 'red' }}>{error}</p>}

                    <div style={{ textAlign: 'center' }}> 
                        {loading && (
                            <div style={{ display: 'inline-block' }}> 
                                <Spinner animation="border" role="status" />
                            </div>
                        )}
                    </div>

                    <button type="submit" onClick={handleRegister} className="login__button">
                        Register
                    </button>
                </form>

                <p>Already a registered User? Click below to Log In</p>
                <Link to="/login">
                    <button className="signin__button">Log In</button>
                </Link>
            </div>
        </div>
    );
}

export default Registration;

