
export default [
    {"Problem": "Two Sum", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/two-sum"},
    {"Problem": "Add Two Numbers", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/add-two-numbers"},
    {"Problem": "Longest Substring Without Repeating Characters", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/longest-substring-without-repeating-characters"},
    {"Problem": "Median of Two Sorted Arrays", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/median-of-two-sorted-arrays"},
    {"Problem": "Longest Palindromic Substring", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/longest-palindromic-substring"},
    {"Problem": "Reverse Integer", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/reverse-integer"},
    {"Problem": "String to Integer (atoi)", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/string-to-integer-atoi"},
    {"Problem": "Palindrome Number", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/palindrome-number"},
    {"Problem": "Regular Expression Matching", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/regular-expression-matching"},
    {"Problem": "Container With Most Water", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/container-with-most-water"},
    {"Problem": "Integer to Roman", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/integer-to-roman"},
    {"Problem": "Longest Common Prefix", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/longest-common-prefix"},
    {"Problem": "3Sum", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/3sum"},
    {"Problem": "3Sum Closest", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/3sum-closest"},
    {"Problem": "Letter Combinations of a Phone Number", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/letter-combinations-of-a-phone-number"},
    {"Problem": "4Sum", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/4sum"},
    {"Problem": "Valid Parentheses", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/valid-parentheses"},
    {"Problem": "Merge Two Sorted Lists", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/merge-two-sorted-lists"},
    {"Problem": "Generate Parentheses", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/generate-parentheses"},
    {"Problem": "Merge k Sorted Lists", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/merge-k-sorted-lists"},
    {"Problem": "Swap Nodes in Pairs", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/swap-nodes-in-pairs"},
    {"Problem": "Reverse Nodes in k-Group", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/reverse-nodes-in-k-group"},
    {"Problem": "Remove Duplicates from Sorted Array", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/remove-duplicates-from-sorted-array"},
    {"Problem": "Implement strStr()", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/implement-strstr"},
    {"Problem": "Next Permutation", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/next-permutation"},
    {"Problem": "Longest Valid Parentheses", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/longest-valid-parentheses"},
    {"Problem": "Find First and Last Position of Element in Sorted Array", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/find-first-and-last-position-of-element-in-sorted-array"},
    {"Problem": "Search Insert Position", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/search-insert-position"},
    {"Problem": "Sudoku Solver", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/sudoku-solver"},
    {"Problem": "Count and Say", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/count-and-say"},
    {"Problem": "First Missing Positive", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/first-missing-positive"},
    {"Problem": "Trapping Rain Water", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/trapping-rain-water"},
    {"Problem": "Multiply Strings", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/multiply-strings"},
    {"Problem": "Wildcard Matching", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/wildcard-matching"},
    {"Problem": "Jump Game II", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/jump-game-ii"},
    {"Problem": "Permutations", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/permutations"},
    {"Problem": "Rotate Image", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/rotate-image"},
    {"Problem": "Group Anagrams", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/group-anagrams"},
    {"Problem": "Pow(x, n)", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/powx-n"}
];