import React, { useState } from 'react';
import './Login.css';
import { Link, useHistory } from 'react-router-dom';
import { auth, db } from './firebase';
import { useStateValue } from './StateProvider';
import Registration from './Registration'; // Import the Registration component
import Spinner from 'react-bootstrap/Spinner';

function Login() {
    const history = useHistory();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [{ user }, dispatch] = useStateValue();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const signIn = (e) => {
        e.preventDefault();
        setLoading(true);
        // Firebase login
        auth.signInWithEmailAndPassword(email, password)
            .then((auth) => {
                history.push('/');
            })
            .catch((error) => {
                const startIndex = error.message.indexOf(':') + 2;
                const endIndex = error.message.indexOf('.', startIndex);
                const trimmedErrorMessage = error.message.substring(startIndex, endIndex);
                
                setError(trimmedErrorMessage);
              })
            .finally(() => setLoading(false));
    };

    return (
        <div className="login">
            <Link to="/" className="login__logo" style={{ textDecoration: 'none' }}>
            Tech<span>At</span>Phone <span>S</span>heet
            </Link>

            <div className="login__container">
                <h2>Sign In</h2>

                <form>
                    <h5>E-mail</h5>
                    <input
                        type="text"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />

                    <h5>Password</h5>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                    <div style={{ textAlign: 'center' }}> {/* Apply center alignment */}
                        {loading && (
                            <div style={{ display: 'inline-block' }}> {/* Center the spinner */}
                                <Spinner animation="border" role="status" />
                            </div>
                        )}
                    </div>
                    
                    <button type="submit" onClick={signIn} className="login__button">
                        Log in
                    </button>
                </form>

                <p>If you are a new User, Click below to Create an Account</p>
                {/* Link to the Registration component */}
                
                <Link to="/register">
                <button className='signin__button'>Create an Account</button>
                </Link>
            </div>
        </div>
    );
}

export default Login;