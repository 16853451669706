import React from 'react';
import './Banner.css';
import techatphoneLogo from './img/techatphone.png';
import bannerImg from './img/bannerImg2.png';


function Banner() {
    
  return (
    <div className='banner'>
        <div className='bannerIn'>
          <div className='banner_top'>
            <div className='banner_left'>
                  <h1>Welcome to TechAtPhone SDE Sheet!!</h1>
                  <p>Mastering Data Structures and Algorithms: Prepare from different sheets in one place.</p>
              </div>
              <div className='banner_right'>
                <div>
                <img src={bannerImg} />
                </div>

              </div>
          </div>

          <div className='visit_tech'>
            <div>
                For Daily Jobs and Tech Updates Visit
            </div>
            <a href='https://techatphone.in' target='_blank'><img src={techatphoneLogo} className='techatphonelogo'/></a>
        </div>
            
        </div>
        
    </div>
  )
}

export default Banner;