
export default [
    { "Problem": "Design Linked List", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/design-linked-list" },
    { "Problem": "Design Circular Queue", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/design-circular-queue" },
    { "Problem": "Find And Replace in String", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/find-and-replace-in-string" },
    { "Problem": "Robot Room Cleaner", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/robot-room-cleaner" },
    { "Problem": "Rectangle Overlap", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/rectangle-overlap" },
    {"Problem": "Two Sum", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/two-sum"},
    {"Problem": "Add Two Numbers", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/add-two-numbers"},
    {"Problem": "Longest Substring Without Repeating Characters", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/longest-substring-without-repeating-characters"},
    {"Problem": "Median of Two Sorted Arrays", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/median-of-two-sorted-arrays"},
    {"Problem": "Longest Palindromic Substring", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/longest-palindromic-substring"},
    {"Problem": "Reverse Integer", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/reverse-integer"},
    {"Problem": "String to Integer (atoi)", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/string-to-integer-atoi"},
    {"Problem": "Palindrome Number", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/palindrome-number"},
    {"Problem": "Regular Expression Matching", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/regular-expression-matching"},
    {"Problem": "Container With Most Water", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/container-with-most-water"},
    {"Problem": "Integer to Roman", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/integer-to-roman"},
    {"Problem": "Longest Common Prefix", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/longest-common-prefix"},
    {"Problem": "3Sum", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/3sum"},
    { "Problem": "Backspace String Compare", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/backspace-string-compare" },
    { "Problem": "Maximize Distance to Closest Person", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/maximize-distance-to-closest-person" },
    { "Problem": "Implement Rand10() Using Rand7()", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/implement-rand10-using-rand7" },
    { "Problem": "Middle of the Linked List", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/middle-of-the-linked-list" },
    { "Problem": "Random Pick with Weight", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/random-pick-with-weight" },
    { "Problem": "Possible Bipartition", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/possible-bipartition" },
    { "Problem": "Maximum Frequency Stack", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/maximum-frequency-stack" },
    { "Problem": "Sort an Array", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/sort-an-array" },
    { "Problem": "Sort Array By Parity II", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/sort-array-by-parity-ii" },
    { "Problem": "Minimum Area Rectangle", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/minimum-area-rectangle" },
    { "Problem": "Binary Tree Cameras", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/binary-tree-cameras" },
    { "Problem": "Fibonacci Number", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/fibonacci-number" },
    { "Problem": "Distribute Coins in Binary Tree", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/distribute-coins-in-binary-tree" },
    { "Problem": "Time Based Key-Value Store", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/time-based-key-value-store" },
    { "Problem": "String Without AAA or BBB", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/string-without-aaa-or-bbb" },
    { "Problem": "Vertical Order Traversal of a Binary Tree", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/vertical-order-traversal-of-a-binary-tree" },
    { "Problem": "Cousins in Binary Tree", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/cousins-in-binary-tree" },
    { "Problem": "Rotting Oranges", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/rotting-oranges" },
    { "Problem": "Max Consecutive Ones III", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/max-consecutive-ones-iii" },
    { "Problem": "Construct Binary Search Tree from Preorder Traversal", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/construct-binary-search-tree-from-preorder-traversal" },
    { "Problem": "Stream of Characters", "Done": false, "Notes": "", "URL": "https://leetcode.com/problems/stream-of-characters" },
    { "Problem": "Maximum Level Sum of a Binary Tree", "Done":false,"Notes": "", "URL": "https://leetcode.com/problems/maximum-level-sum-of-a-binary-tree" },
    {"Problem": "As Far from Land as Possible", "Notes": "", "Done": false, "URL": "https://leetcode.com/problems/as-far-from-land-as-possible"},
    {"Problem": "Longest Duplicate Substring", "Notes": "", "Done": false, "URL": "https://leetcode.com/problems/longest-duplicate-substring"},
    {"Problem": "Design File System", "Notes": "", "Done": false, "URL": "https://leetcode.com/problems/design-file-system"},
    {"Problem": "Minimum Knight Moves", "Notes": "", "Done": false, "URL": "https://leetcode.com/problems/minimum-knight-moves"},
    {"Problem": "Shortest Path in Binary Matrix", "Notes": "", "Done": false, "URL": "https://leetcode.com/problems/shortest-path-in-binary-matrix"},
    {"Problem": "Car Pooling", "Notes": "", "Done": false, "URL": "https://leetcode.com/problems/car-pooling"},
    {"Problem": "Lowest Common Ancestor of Deepest Leaves", "Notes": "", "Done": false, "URL": "https://leetcode.com/problems/lowest-common-ancestor-of-deepest-leaves"},
    {"Problem": "Snapshot Array", "Notes": "", "Done": false, "URL": "https://leetcode.com/problems/snapshot-array"},
    {"Problem": "Number of Dice Rolls With Target Sum", "Notes": "", "Done": false, "URL": "https://leetcode.com/problems/number-of-dice-rolls-with-target-sum"},
    {"Problem": "Number of Days Between Two Dates", "Notes": "", "Done": false, "URL": "https://leetcode.com/problems/number-of-days-between-two-dates"},
    {"Problem": "Longest Happy String", "Notes": "", "Done": false, "URL": "https://leetcode.com/problems/longest-happy-string"},
    {"Problem": "Search Suggestions System", "Notes": "", "Done": false, "URL": "https://leetcode.com/problems/search-suggestions-system"},
    {"Problem": "Find Winner on a Tic Tac Toe Game", "Notes": "", "Done": false, "URL": "https://leetcode.com/problems/find-winner-on-a-tic-tac-toe-game"},
    {"Problem": "Count Square Submatrices with All Ones", "Notes": "", "Done": false, "URL": "https://leetcode.com/problems/count-square-submatrices-with-all-ones"},
    {"Problem": "Find N Unique Integers Sum up to Zero", "Notes": "", "Done": false, "URL": "https://leetcode.com/problems/find-n-unique-integers-sum-up-to-zero"},
    {"Problem": "Minimum Insertion Steps to Make a String Palindrome", "Notes": "", "Done": false, "URL": "https://leetcode.com/problems/minimum-insertion-steps-to-make-a-string-palindrome"}
];
  