import React from 'react';
import './Leetcode.css';
import googleLogo from './img/google.png';
import microLogo from './img/microsoft.png';
import amazonLogo from './img/amazon.png';

import { useStateValue } from './StateProvider';
import { Link } from 'react-router-dom';

import AmazonList from "./AmazonList";

function Leetcode() {
    const [{},dispatch] = useStateValue();
    const addAmazon = () =>{
        dispatch({
            type:'ADD_TO_IMP_QSTN',
            item : AmazonList,
        })
    }

    const addGoogle = () =>{
        dispatch({
            type:'ADD_TO_IMP_QSTN',
            item : AmazonList,
        })
    }

    const addMicrosoft = () =>{
        dispatch({
            type:'ADD_TO_IMP_QSTN',
            item : AmazonList,
        })
    }

  return (
    <div className='leetcodeqstns'>
        <div className='title__leet'>
            <div >Top LeetCode Questions Asked By Companies</div>
        </div>
        
        <div className="grid">
            <Link to="/amazon/topquestions" className='styleLink'>
                <div className = "item" onClick={addAmazon}>
                    <div className="card-service">
                        <div className="header">
                            <img src={amazonLogo} />
                        </div>
                    
                    </div>
                </div>
            </Link>
            
            <Link to="/google/topquestions" className='styleLink' >
                <div className = "item" onClick={addGoogle}>
                    <div className="card-service">
                        <div className="header">
                            <img src={googleLogo} />
                        </div>
                        
                    </div>
                </div>
            </Link>
            
            <Link to="/microsoft/topquestions" className='styleLink'>
                <div className = "item" onClick={addMicrosoft}>
                    <div className="card-service">
                        <div className="header">
                            <img src={microLogo}/>
                        </div>
                        
                    </div>
                </div>
            </Link>
        </div>
    </div>
    
  )
}

export default Leetcode;