import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import { useStateValue } from './StateProvider';
import { auth } from './firebase';


import './Header.css';


function Header() {
  const [{ basket, user }, dispatch] = useStateValue();
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleSignOut = () => {
    setShowConfirmation(true);
  };

  const confirmSignOut = () => {
    auth.signOut();
    setShowConfirmation(false);
  };

  const cancelSignOut = () => {
    setShowConfirmation(false);
  };

  const username = user?.email.split('@').shift().substring(0, 12);
  

  return (
    <div className="header">
      <Link to="/" className="login__logo1" style={{ textDecoration: 'none' }}>
        Tech<span>At</span>Phone <span>S</span>heet
      </Link>

      <div className="header_nav">
        <Link to={!user && '/login'} style={{ textDecoration: 'none' }}>
          <div className="header_options" onClick={handleSignOut}>
            <span className="header_optionLineOne">
              Hello <span className="userName">{user ? username : 'Guest'}</span>
            </span>
            <span className="header_optionLineTwo">
              {user ? 'Sign Out' : 'Sign In'}
            </span>
          </div>
        </Link>
      </div>

      

      {showConfirmation && (
          <div className='note-areas'>
          <div className='note-containers'>
              <div className='question-titles' style={{ color: 'black' }}> Confirm Log Out</div>
              <div className='note-sections'>Are you sure you want to Sign Out?</div>
              <div className='button-containers'>
                  
                  <button className='note-saves' onClick={confirmSignOut}>
                      Log Out
                  </button>
                  <button className='note-exits' onClick={cancelSignOut}>
                      Cancel
                  </button>
              </div>
          </div>
      </div>
      )}



    </div>
  );
}

export default Header;














/*

<>
            <div className='note-area'>
                <div className='note-container'>
                    <div className='question-title' style={{ color: 'black' }}></div>
                    <textarea maxLength='432' className='note-section' placeholder='your notes here' onChange={addnewnotes}></textarea>
                    <div className='button-container'>
                        <button className='note-exit' onClick={saveAndExitNotes}>
                            Close
                        </button>
                        <button className='note-save' onClick={onadd}>
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </>



*/